/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #999;
  border-top: 1px solid #e4e4e4;
  font-size: .9rem;
  p {
    margin: 0 0 5px 0;
  }
  a {
    color: #999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}
.font-weight-strong{
  font-weight: 600;
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  .dropdown-list{
    clear: right;
    max-width: 300px;
    z-index: 1000;
  }
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 90%;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      strong {
        font-size: 16px;
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
    }
    a.livechat {
      float: right;
      margin: 0;
      padding: 0 12px 2px 12px;
      font-weight: 400;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:hover{
        background: darken($theme-primary, 7.5%);
      }
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\e830";
        color: inherit;
        font-family: icons;
        font-weight: 400;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

#banner-wrap {
  background: #f3f7fc;
  background-size: cover;
  &.florida{
    background: #cbe7fc url(/images/banner_bg_fl.jpg) no-repeat 50% bottom/cover;
    #banner{
      background: none;
      ul{
        font-size: 1em;
      }
    }
  }
}
#points-wrap{
  border-color: #b8d7e6;
  .text{
    color: #316596;
    font-size: 15px;
  }
}
.cta-wrap{
  background: $theme-primary;
  color: #fff;
  .cta-btn{
    width: 220px;
  }
}
.price span.from {
  font-size: 18px;
  margin-right: 6px;
  text-shadow: none;
  display: block;
}
.blue-border{
  border: 4px solid #1f93d2;
}
/* RECORD TABLE */
#records{
	border: 1px solid #999;
	border-top: 5px solid #999;
}

#record-table {
	.record, .pricing {
		font-size: 24px;
		font-weight: bold;
	}
  .record, .pricing, .order, .link {
		width: 100%;
	}
	.line {
		border-bottom: 1px dotted #66ccff;
		font-size: 0;
		&:first-child {
			border-top: 1px dotted #66ccff;
		}
		&:hover {
			background: #E5F6FF;
		}
	}
	.link, .rates {
		font-size: 14px;
	}
	.link{
		cursor: pointer;
	}
	.record a {
		color: #555;
	}
}
.inner #record-table {
	.record, .pricing {
		font-size: 18px;
	}
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #points-wrap {
    #points {
      h2 {
        &:before {
          display: block;
          margin: 0 auto 10px auto;
          width: 60px;
          height: 60px;
        }
        &.step-2:before {
          background-position: -60px 0;
        }
        &.step-3:before {
          background-position: -120px 0;
        }
      }
    }
  }
  #record-table {
	  .pricing, .link, .rates, .order {
		  width: 33.3333333%;
	  }
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      margin-right: 15px;
    }
  }
  #record-table {
		.record {
			width: 40%;
		}
		.pricing {
			width: 20%;
		}
		.order {
			margin-left: 20% !important;
			width: 20%;
		}
		.link {
			margin-left: -40% !important;
			width: 20%;
		}
		.rates {
			width: 20%;
		}
	}
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) { 
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 75%;
  }
  #banner-wrap {
    #banner {
      background: url(../images/banner-person.png) no-repeat -5% 0;
      ul {
        font-size: 1.1em;
      } 
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
